import React from "react";
import {
  Button,
  Link,
  Hidden,
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import BackgroundImage from "../BackgroundImage";
import Image from "../Image";

const useStyles = makeStyles((theme) => ({
  buildSection: {
    minHeight: "95vh",
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  sectionContent: {
    width: "40vw",
    marginLeft: "6vw",
    marginBottom: "75px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginBottom: 0,
      marginTop: "12vh",
    },
  },
  joinSubtitle: {
    paddingTop: theme.spacing(3),
    fontWeight: "bold",
    fontSize: "20px",
    textShadow: "0px 2px 2px rgba(0, 0, 0, 0.4)",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  peanutFloat: {
    position: "absolute",
    right: "114px",
    marginTop: "-110vh",
    height: "800px",
    animation: "$floatAnim 3s ease-in-out infinite",
  },
  "@keyframes floatAnim": {
    "0%": {
      transform: "translateY(3rem)",
    },
    "50%": {
      transform: "translateY(0)",
    },
    "100%": {
      transform: "translateY(3rem)",
    },
  },
}));

const HomepageBuild = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <section>
      <BackgroundImage
        src={
          smScreen
            ? "/images/banner_build_mobile.jpg"
            : "/images/banner_build.jpg"
        }
      >
        <div className={classes.buildSection}>
          <div className={classes.sectionContent}>
            <Typography variant="h1" component="h2">
              Make Your Own Games
            </Typography>
            <Typography className={classes.joinSubtitle}>
              Easily design and build all sorts of party games for everyone to
              enjoy, no coding required.All that&apos;s needed is a bit of
              creativity.
            </Typography>
            <Link
              href="https://blog.blankos.com/heartbreaker-mini-build-challenge-winners"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                color="secondary"
                size="large"
                style={{ marginTop: "32px" }}
              >
                Check it Out
              </Button>
            </Link>
          </div>
        </div>
        <Hidden smDown>
          <div className={classes.peanutFloat}>
            <Image
              src="/images/peanut.png"
              alt="Peanut with balloon"
              width={329}
              height={800}
            />
          </div>
        </Hidden>
      </BackgroundImage>
    </section>
  );
};

export default HomepageBuild;

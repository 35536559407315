import React from "react";
import type { BoxProps } from "@material-ui/core";
import { Box } from "@material-ui/core";
import type { CloudflareImageProps } from "../lib/cloudflare";
import { transformImageSrc } from "../lib/cloudflare";

const Image: React.FC<BoxProps & CloudflareImageProps> = (props) => {
  const { src, width, height, format, quality, ...rest } = props;

  // TODO move this to a central spot?
  const imgSrc = React.useMemo(
    () =>
      transformImageSrc({
        src: src,
        width: width,
        height: height,
        format: format,
        quality: quality,
      }),
    [src, width, height, format, quality],
  );

  return (
    <Box
      {...rest}
      style={{
        backgroundImage: `url('${imgSrc}')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        ...rest.style,
      }}
    >
      {props.children}
    </Box>
  );
};

export default Image;

import React from "react";
import {
  Container,
  Grid,
  Hidden,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import RedditIcon from "@material-ui/icons/Reddit";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import Image from "../Image";

const useStyles = makeStyles((theme) => ({
  sectionContent: {
    width: "30vw",
    marginLeft: "6vw",
    marginBottom: "12vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginBottom: 0,
      marginTop: "12vh",
    },
  },
  sectionHeader: {
    fontFamily: "Upgrade Black",
    fontSize: "100px",
    textShadow: "0px 3px 6px rgba(0,0,0,0.4)",
    margin: "0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontSize: "40px",
    },
  },
  joinSubtitle: {
    paddingTop: theme.spacing(3),
    fontWeight: "bold",
    fontSize: "20px",
    textShadow: "0px 2px 2px rgba(0, 0, 0, 0.4)",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  socialIcon: {
    color: "white",
    padding: "0 5px",
    "& > *": {
      height: "auto",
      width: "100%",
      maxWidth: "80px",
    },
  },
  billyLean: {
    right: "0",
    position: "absolute",
    marginTop: "-50vh",
    "& > *": {
      width: "750px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "-40vw",
      zIndex: "-1",
      "& > *": {
        width: "305px !important",
        height: "335px !important",
      },
    },
  },
  emotes: {
    position: "relative",
    marginTop: "-120vh",
    marginLeft: "2.6vw",
    width: "95%",
  },
}));

// TODO cleanup

const HomepageSocial = () => {
  const classes = useStyles();

  return (
    <section>
      <Container
        maxWidth="lg"
        style={{
          marginTop: "10vh",
          marginBottom: "12vh",
        }}
      >
        <Typography variant="h1" component="h2">
          Keep In Touch
        </Typography>
        <p className={classes.joinSubtitle}>
          Get the inside scoop on all the new news, big announcements, and
          Blankos exclusives.
        </p>

        <Grid container>
          <Grid item xs={12} md={6}>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <Link
                  className={classes.socialIcon}
                  href="https://discord.gg/playblankos"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="homepage-social-discord"
                >
                  <Image
                    src="/images/icons/discord_logo.png"
                    height={100}
                    width={100}
                    alt="Discord icon"
                  />
                </Link>
              </Grid>
              <Grid item xs={2}>
                <Link
                  className={classes.socialIcon}
                  href="https://www.reddit.com/r/PlayBlankos"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="homepage-social-subreddit"
                >
                  <RedditIcon />
                </Link>
              </Grid>
              <Grid item xs={2}>
                <Link
                  className={classes.socialIcon}
                  href="https://twitter.com/PlayBlankos"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="homepage-social-twitter"
                >
                  <TwitterIcon />
                </Link>
              </Grid>
              <Grid item xs={2}>
                <Link
                  className={classes.socialIcon}
                  href="https://facebook.com/PlayBlankos"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="homepage-social-facebook"
                >
                  <FacebookIcon />
                </Link>
              </Grid>
              <Grid item xs={2}>
                <Link
                  className={classes.socialIcon}
                  href="https://www.instagram.com/playblankos"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="homepage-social-instagram"
                >
                  <InstagramIcon />
                </Link>
              </Grid>
              <Grid item xs={2}>
                <Link
                  className={classes.socialIcon}
                  href="https://www.youtube.com/playblankos"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="homepage-social-youtube"
                >
                  <YouTubeIcon />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Hidden only={["xs", "sm", "md"]}>
          <Image
            src="/images/pop_marks.png"
            alt="Pop Art Exclamation Marks"
            width={494}
            height={435}
            style={{
              position: "absolute",
              left: "0",
            }}
          />
        </Hidden>
      </Container>

      <div className={classes.billyLean}>
        <Image
          src="/images/billy_lean.png"
          alt="wiley billy"
          width={610}
          height={671}
        />
      </div>
    </section>
  );
};

export default HomepageSocial;

import { useRouter } from "next/router";
import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import ExpandIcon from "@material-ui/icons/Add";
import CollapseIcon from "@material-ui/icons/Remove";
import { summary } from "../../data/faq";
import Routes from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  faqPanels: {
    padding: "100px 0",
  },
}));

const HomepageFaq = () => {
  const classes = useStyles();
  const router = useRouter();

  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  const faqPanels = summary.map((faqPanel, index) => {
    const key = `FaqPanel-${index}`;

    return (
      <Accordion
        style={{
          backgroundColor: "#171717",
          color: "#fff",
          border: "1px solid #fff",
        }}
        key={key}
        expanded={expanded === key}
        onChange={handleChange(key)}
      >
        <AccordionSummary id={`${key}-header`} aria-controls={`${key}-content`}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontSize: "25px",
                flexBasis: "90%",
                flexShrink: 0,
              }}
            >
              {faqPanel.question}
            </Typography>{" "}
            {expanded === key ? (
              <CollapseIcon style={{ fontSize: "28px" }} />
            ) : (
              <ExpandIcon style={{ fontSize: "28px" }} />
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            style={{ fontSize: "18px" }}
            dangerouslySetInnerHTML={{ __html: faqPanel.answer }}
          />
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <section>
      <Container
        maxWidth="md"
        style={{
          fontSize: "28px",
          padding: "100px 0",
        }}
      >
        <Typography variant="h1" component="h2" style={{ textAlign: "center" }}>
          FAQs
        </Typography>

        <div className={classes.faqPanels}>{faqPanels}</div>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => router.push(Routes.Faqs)}
          >
            View All FAQs
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default HomepageFaq;

import React from "react";
import Slider from "react-slick";
import type { Theme } from "@material-ui/core";
import { Box, Container, Grid, Hidden, makeStyles } from "@material-ui/core";
import Image from "../Image";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: "-100px",
  },
  content: {
    padding: "150px 0 75px 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  blockquote: {
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: "22px",
    margin: "0 0 30px 0",
    padding: theme.spacing(0, 3),
    background: "#35373B",
    borderTopLeftRadius: "14px",
    borderTopRightRadius: "14px",
    borderBottomRightRadius: "14px",
    position: "relative",
    height: "185px",
    display: "flex",
    alignItems: "center",
    filter: "drop-shadow(0px 4px 20px #000000)",
    "&::after": {
      content: "''",
      position: "absolute",
      top: "100%",
      left: "0",
      width: "3px",
      height: "0",
      borderTop: "30px solid #35373B",
      borderRight: "30px solid transparent",
    },
  },
}));

interface Accolade {
  quote: string;
  src: string;
  alt: string;
  width: number;
  height: number;
}

const HomepageAccolades: React.FC = () => {
  const classes = useStyles();

  const accolades: Accolade[] = [
    {
      quote:
        "I can’t wait to get my hands on Blankos Block Party and explore that wacky little world on my own.",
      src: "/images/gaming_trend_logo.png",
      width: 160,
      height: 40,
      alt: "Gaming Trend logo",
    },
    {
      quote:
        "...a variety of gameplay, fun creation tools and an amazing style that makes for some rather memorable virtual toys to collect.",
      src: "/images/hardcore_gamer_logo.png",
      width: 261,
      height: 50,
      alt: "Hardcore Gamer logo",
    },
    {
      quote:
        "...the fresh approach to player ownership is poised to disrupt the industry as we know it.",
      src: "/images/aotf_logo.png",
      width: 150,
      height: 40,
      alt: "AOTF logo",
    },
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: 1,
    centerPadding: "16px",
  };

  return (
    <Box className={classes.root}>
      <Hidden smDown>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            {accolades.map((accolade, index) => (
              <Grid item xs={4} key={index}>
                <blockquote className={classes.blockquote}>
                  &quot;{accolade.quote}&quot;
                </blockquote>
                <Image {...accolade} alt={accolade.alt} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Hidden>
      <Hidden mdUp>
        <Slider {...(sliderSettings as any)}>
          {accolades.map((accolade, index) => (
            <Box paddingY={6} paddingX={2} boxSizing="border-box" key={index}>
              <blockquote className={classes.blockquote}>
                &quot;{accolade.quote}&quot;
              </blockquote>
              <Image {...accolade} alt={accolade.alt} />
            </Box>
          ))}
        </Slider>
      </Hidden>
    </Box>
  );
};

export default HomepageAccolades;

import Head from "next/head";

interface Props {
  title?: string;
  description?: string;
  image?: string;
  noIndex?: boolean;
}

const SEO = ({ title, description, image, noIndex = false }: Props) => {
  const desc =
    description ??
    "Blankos Block Party is a new multiplayer universe owned and built by you. Now in Early Access for PC and macOS!"; // TODO: I18N / Next.js Router
  const seo = {
    title: (title as string)?.length > 0 ? (title as string).slice(0, 70) : "",
    titleSuffix: "Blankos",
    description: desc?.slice(0, 160),
    username: "@PlayBlankos",
    image: image ?? "https://blankos.com/BBP_PLAY.jpg",
    locale: "en-US", // TODO: I18N / Next.js Router
    language: "en", // TODO: I18N / Next.js Router
    year: new Date().getFullYear(),
  };

  const fullTitle = seo.title
    ? `${seo.title} - ${seo.titleSuffix}`
    : seo.titleSuffix;

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')
  // Structured Data Testing Tool >>
  // https://search.google.com/structured-data/testing-tool

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    headline: seo.description,
    inLanguage: seo.language, // TODO: I18N / Next.js Router
    description: seo.description,
    name: fullTitle,
    copyrightYear: seo.year,
    image: {
      "@type": "ImageObject",
      url: `${seo.image}`,
    },
  };

  return (
    <>
      <Head>
        {/* Since Next.js knows what language the user is visiting it will automatically add the lang attribute to the <html> tag. */}
        {/* https://nextjs.org/docs/advanced-features/i18n-routing#search-engine-optimization */}
        <title>{fullTitle}</title>
        <meta name="description" content={seo.description} />

        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>

        <meta property="og:locale" content={seo.locale} />
        {/* // TODO: I18N / Next.js Router */}
        <meta property="og:title" content={fullTitle} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        <meta property="og:image:alt" content={seo.description} />

        {seo.username && <meta name="twitter:creator" content={seo.username} />}
        <meta name="twitter:title" content={fullTitle} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />
        <meta name="twitter:image:alt" content={seo.description} />

        {noIndex ? <meta name="robots" content="noindex" /> : null}
      </Head>
    </>
  );
};

export default SEO;

import Link from "next/link";
import React from "react";
import { Button, Hidden, makeStyles, Typography } from "@material-ui/core";
import BackgroundImage from "../BackgroundImage";
import Image from "../Image";

const useStyles = makeStyles((theme) => ({
  connectSection: {
    minHeight: "95vh",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  sectionContent: {
    width: "30vw",
    marginLeft: "6vw",
    marginBottom: "12vh",
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginBottom: 0,
      marginTop: "12vh",
    },
  },
  joinSubtitle: {
    paddingTop: theme.spacing(3),
    fontWeight: "bold",
    fontSize: "20px",
    textShadow: "0px 2px 2px rgba(0, 0, 0, 0.4)",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  emotes: {
    position: "relative",
    marginTop: "-120vh",
    zIndex: 1,
  },
}));

const HomepageConnect = () => {
  // TODO get rid of this wrapper
  return (
    <>
      <Hidden smDown>
        <BackgroundImage src="/images/banner_connect.jpg">
          <Content />
        </BackgroundImage>
      </Hidden>
      <Hidden mdUp>
        <BackgroundImage src="/images/banner_connect_mobile.jpg">
          <Content />
        </BackgroundImage>
      </Hidden>
    </>
  );
};

const Content = () => {
  const classes = useStyles();

  return (
    <section>
      <div className={classes.connectSection}>
        <div className={classes.sectionContent} style={{ marginRight: "10vw" }}>
          <Typography variant="h1" component="h2">
            Iconic Designs
          </Typography>
          <Typography className={classes.joinSubtitle}>
            We&apos;ve partnered with some of the world&apos;s most talented
            artists, designers, and creators for limited releases found only
            here. If you&apos;d like to work with us for future designs,
            we&apos;d love to hear from you.
          </Typography>
          <Link href="mailto:info@mythical.games" passHref>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              style={{ marginTop: "32px" }}
            >
              Contact Us
            </Button>
          </Link>
        </div>
      </div>

      <Hidden only={["xs", "sm", "md"]}>
        {/* TODO these look weird on mobile */}
        <Image
          src="/images/emotes.png"
          aria-hidden={true}
          width={1518}
          height={876}
          className={classes.emotes}
        />
      </Hidden>
    </section>
  );
};

export default HomepageConnect;
